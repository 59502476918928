var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingSwapCompany },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.updateCurrentCompany },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _c(
        "div",
        { staticClass: "d-flex justify-start" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-3",
              attrs: { color: "primary" },
              on: {
                click: function () {
                  this$1.$router.go(-1)
                },
              },
            },
            [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
            1
          ),
        ],
        1
      ),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "white--text text-h5 mb-4",
                  attrs: { color: "secondary" },
                },
                [_vm._v(" User Edit ")]
              ),
              _c(
                "div",
                [
                  _c(
                    "v-card",
                    { staticClass: "ma-5 pa-2" },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", [
                            _c("div", { staticClass: "px-2" }, [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex mr-2" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "First Name",
                                        dense: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.user.user_fname,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "user_fname", $$v)
                                        },
                                        expression: "user.user_fname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Last Name",
                                        dense: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.user.user_lname,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "user_lname", $$v)
                                        },
                                        expression: "user.user_lname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex mr-2" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Email",
                                        dense: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.user.email,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "email", $$v)
                                        },
                                        expression: "user.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Mobile Phone",
                                        dense: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.user.mobile,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "mobile", $$v)
                                        },
                                        expression: "user.mobile",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "d-flex " },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      label: "Masterclass Role",
                                      items: _vm.RoleOptions,
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.user.user_role,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "user_role", $$v)
                                      },
                                      expression: "user.user_role",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex " },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      label: "AAE Role",
                                      items: _vm.AAERoleOptions,
                                      "item-text": "text",
                                      "item-value": "value",
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.aaeRole,
                                      callback: function ($$v) {
                                        _vm.aaeRole = $$v
                                      },
                                      expression: "aaeRole",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex " },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      label: "Vision Role",
                                      items: _vm.VisionRoleOptions,
                                      "item-text": "text",
                                      "item-value": "value",
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.visionRole,
                                      callback: function ($$v) {
                                        _vm.visionRole = $$v
                                      },
                                      expression: "visionRole",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex mr-2" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Main Category",
                                        items: _vm.MainCategoryOptions,
                                        dense: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.user.subscription_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.user,
                                            "subscription_type",
                                            $$v
                                          )
                                        },
                                        expression: "user.subscription_type",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.InternalStaffOptions,
                                        label: "Branch",
                                        dense: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.user.branch,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "branch", $$v)
                                        },
                                        expression: "user.branch",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-end pa-5" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", plain: "" },
                      on: {
                        click: function () {
                          this$1.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function () {
                          this$1.isPending = true
                        },
                      },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }